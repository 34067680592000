*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  overflow-y: scroll;
}

/* Desktop Nav */

.nav-container {
  background: rgba(0, 0, 0, 0.8);
  height: 44px;
  z-index: 300;
  left: 0;
  top: 0;
  right: 0;
}

.nav-container nav {
  width: 1000px;
  margin: 0 auto;
  padding: 0 8px;
  height: 100%;
}

nav .desktop-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

nav .desktop-nav li a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  opacity: 0.8;
  transition: opacity 400ms;
  font-weight: 500;
}

nav .desktop-nav li a:hover {
  opacity: 1;
}

.link-logo {
  background: url("images/apple-logo.svg");
  display: block;
  background-position: center;
  height: 44px;
  width: 15px;
  background-repeat: no-repeat;
}

.link-search {
  background: url("images/search-icon.svg");
  display: block;
  background-position: center;
  background-size: 18px;
  height: 44px;
  width: 20px;
  background-repeat: no-repeat;
}

.link-bag {
  background: url("images/bag-icon.svg");
  display: block;
  background-position: center;
  background-size: 17px;
  height: 44px;
  width: 20px;
  background-repeat: no-repeat;
}

.link-close {
  background: url("images/close-icon.svg");
  display: block;
  background-position: center;
  background-size: 17px;
  height: 44px;
  width: 20px;
  background-repeat: no-repeat;
}

/* Search Container */

.search-container.hide {
  opacity: 0;
  pointer-events: none;
}

.search-container {
  width: 60%;
  margin: 0 auto;
  padding: 0 42px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
}

.search-container .link-search {
  position: absolute;
  left: 12px;
  opacity: 0.5;
}

.search-container .link-close {
  position: absolute;
  top: 0;
  right: 12px;
  opacity: 0.5;
  cursor: pointer;
  transition: all 400ms;
}

.search-container .link-close:hover {
  opacity: 0.7;
}

.search-container form {
  width: 100%;
  margin: 0 auto;
}

.search-container form input {
  width: 100%;
  height: 44px;
  border: 0;
  outline: none;
  background: transparent;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 17px;
}

/* Overlay */

.overlay.show {
  position: fixed;
  background: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 100;
}

/* Quick Links */

.search-container .quick-links {
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  padding: 16px 8px;
  border-radius: 0 0 16px 16px;
}

.search-container .quick-links h2 {
  text-transform: uppercase;
  font-size: 12px;
  color: #6e6e73;
  margin: 0 32px;
  margin-top: 10px;
}

.search-container .quick-links ul {
  list-style: none;
  margin-top: 12px;
}

.search-container .quick-links ul li a {
  display: inline-block;
  width: 100%;
  padding: 8px 50px;
  font-size: 14px;
  color: #1d1d1f;
  text-decoration: none;
  font-weight: 400;
}

.search-container .quick-links ul li a:hover {
  background: #f5f5f5;
  color: #2997ff;
}

/* Desktop Nav Animation */

.desktop-nav li {
  transition: all 400ms ease;
}

.desktop-nav.hide li {
  opacity: 0;
  transform: scale(0.8);
  pointer-events: none;
}

/* Overlay Animation */

.overlay {
  transition: all 400ms ease;
}

/* Navigation Menu Items Transition Delay */

.desktop-nav li:nth-of-type(1),
.desktop-nav.hide li:nth-of-type(10) {
  transition-delay: 0ms;
}

.desktop-nav li:nth-of-type(2),
.desktop-nav.hide li:nth-of-type(9) {
  transition-delay: 30ms;
}

.desktop-nav li:nth-of-type(3),
.desktop-nav.hide li:nth-of-type(8) {
  transition-delay: 60ms;
}

.desktop-nav li:nth-of-type(4),
.desktop-nav.hide li:nth-of-type(7) {
  transition-delay: 90ms;
}

.desktop-nav li:nth-of-type(5),
.desktop-nav.hide li:nth-of-type(6) {
  transition-delay: 120ms;
}

.desktop-nav li:nth-of-type(6),
.desktop-nav.hide li:nth-of-type(5) {
  transition-delay: 150ms;
}

.desktop-nav li:nth-of-type(7),
.desktop-nav.hide li:nth-of-type(4) {
  transition-delay: 180ms;
}

.desktop-nav li:nth-of-type(8),
.desktop-nav.hide li:nth-of-type(3) {
  transition-delay: 210ms;
}

.desktop-nav li:nth-of-type(9),
.desktop-nav.hide li:nth-of-type(2) {
  transition-delay: 240ms;
}

.desktop-nav li:nth-of-type(10),
.desktop-nav.hide li:nth-of-type(1) {
  transition-delay: 270ms;
}

/* Search Container Animation */

.search-container form,
.search-container .link-search {
  opacity: 1;
  transform: translateX(0);
  transition: all 400ms ease;
  transition-delay: 300ms;
}

.search-container.hide form,
.search-container.hide .link-search {
  opacity: 0;
  transform: translateX(50px);
}

.search-container .link-search {
  opacity: 0.6;
}

.search-container.hide .link-close {
  opacity: 0;
}

.search-container .link-close {
  opacity: 0.5;
  transition: all 400ms ease;
  transition-delay: 400ms;
}

.search-container.hide .quick-links h2 {
  opacity: 0;
  transform: translateX(50px);
}

.search-container .quick-links h2 {
  opacity: 1;
  transform: translateX(0);
  transition: all 400ms ease;
  transition-delay: 100ms;
}

.search-container.hide .quick-links ul li {
  transform: translateX(60px);
  opacity: 0;
}

.search-container .quick-links ul li {
  opacity: 1;
  transform: translateX(0);
  transition: all 400ms ease;
}

.search-container .quick-links ul li:nth-of-type(1) {
  transition-delay: 120ms;
}

.search-container .quick-links ul li:nth-of-type(2) {
  transition-delay: 140ms;
}

.search-container .quick-links ul li:nth-of-type(3) {
  transition-delay: 160ms;
}

.search-container .quick-links ul li:nth-of-type(4) {
  transition-delay: 180ms;
}

.search-container .quick-links ul li:nth-of-type(5) {
  transition-delay: 200ms;
}

/* Hidden Items */

.mobile-nav,
.mobile-search-container {
  display: none;
}

/* Media Queries */

@media (max-width: 1100px) {
  .nav-container nav {
    width: 100%;
    padding: 0 32px;
  }

  #links-container {
    max-width: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (max-width: 768px) {
  .nav-container .desktop-nav {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0vh;
    background: #000;
    justify-content: start;
    overflow: hidden;
    z-index: -1;
    transition: all 1000ms ease;
  }

  #links-container a:not(.order-now) {
    display: none;
  }

  .nav-container.active .desktop-nav {
    height: 100vh;
  }

  .nav-container .desktop-nav li {
    width: 100%;
    padding: 0 32px;
  }

  .nav-container .desktop-nav li:first-child {
    margin-top: 120px;
  }

  .nav-container .desktop-nav .link-logo,
  .nav-container .desktop-nav .link-search,
  .nav-container .desktop-nav .link-bag {
    display: none;
  }

  .nav-container .desktop-nav li a {
    padding: 16px 0;
    display: inline-block;
    border-bottom: 1px solid #616161;
    width: 100%;
    font-size: 17px;
    transform: translateY(-80px);
    opacity: 0;
    transition: all 700ms ease;
  }

  .nav-container.active .desktop-nav li a {
    transform: translateY(0);
    opacity: 1;
  }

  /* Mobile Nav */

  nav .mobile-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    list-style: none;
  }

  nav .menu-icon-container {
    width: 20px;
    height: 44px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  nav .menu-icon {
    position: relative;
    width: 100%;
  }

  nav .menu-icon .line-1,
  nav .menu-icon .line-2 {
    position: absolute;
    height: 1px;
    width: 100%;
    background: #fff;
    transition-property: transform, top;
    transition-delay: 0ms, 160ms;
    transition-duration: 200ms;
  }

  nav .menu-icon .line-1 {
    top: -4px;
  }

  nav .menu-icon .line-2 {
    top: 4px;
  }

  .nav-container.active nav .menu-icon-container .menu-icon .line-1 {
    top: 0;
    transform: rotateZ(45deg);
    transition-property: top, transform;
    transition-delay: 0ms, 160ms;
    transition-duration: 200ms;
  }

  .nav-container.active nav .menu-icon-container .menu-icon .line-2 {
    top: 0;
    transform: rotateZ(-45deg);
    transition-property: top, transform;
    transition-delay: 0ms, 160ms;
    transition-duration: 200ms;
  }

  /* Bag Icon Animation */

  .nav-container.active .mobile-nav .link-bag {
    transform: translateY(8px);
    opacity: 0;
    pointer-events: none;
  }

  .nav-container .mobile-nav .link-bag {
    transition: all 1000ms ease;
  }

  /* Search Box */

  .mobile-search-container input {
    width: 100%;
    padding: 12px 36px;
    font-family: "Inter", sans-serif;
    font-size: 17px;
    background: #1b1b1b;
    border: 0;
    color: #fff;
    border-radius: 8px;
    outline: none;
  }

  .mobile-search-container {
    position: relative;
    padding: 0 16px;
    margin-top: -30px;
    border-bottom: 1px solid #616161;
    display: flex;
    padding-bottom: 16px;
    align-items: center;
    transform: rotateX(90deg);
    opacity: 0;
    transition: all 600ms ease;
  }

  .nav-container.active .mobile-search-container {
    transform: rotateX(0deg);
    margin-top: 10px;
    opacity: 1;
  }

  .mobile-search-container .link-search {
    position: absolute;
    left: 24px;
    opacity: 0.5;
    background-size: 15px;
  }

  /* Nav Move Up */

  .nav-container nav.move-up {
    margin-top: -40px;
  }

  /* Cancel Button */

  .mobile-search-container .cancel-btn {
    color: #2997ff;
    font-size: 17px;
    font-weight: 400;
    cursor: pointer;
    width: 0px;
    overflow: hidden;
    transition: all 400ms ease;
  }

  .mobile-search-container .search-bar.active + .cancel-btn {
    padding: 0 16px;
    width: 74px;
  }

  .mobile-search-container .search-bar {
    flex: 1;
  }

  /* Desktop Nav Move Down */

  nav .desktop-nav.move-down li:first-child {
    margin-top: 150px;
  }

  nav .desktop-nav.move-down li {
    opacity: 0;
    pointer-events: none;
  }

  /* Quick Links */

  .mobile-search-container .search-bar.active ~ .quick-links {
    top: 80px;
    opacity: 1;
    pointer-events: auto;
    transition-delay: 40ms;
  }

  .mobile-search-container .quick-links {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 32px;
    opacity: 0;
    pointer-events: none;
    top: 10px;
    transition: all 400ms ease;
  }

  .mobile-search-container .quick-links ul {
    list-style: none;
  }

  .mobile-search-container .quick-links h2 {
    color: #86868b;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .mobile-search-container .quick-links ul li a {
    padding: 16px 0;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid #616161;
    width: 100%;
  }

  .mobile-search-container .quick-links ul li a:hover {
    color: #2997ff;
  }

  /* Nav Animation */

  .nav-container nav {
    transition: all 400ms ease;
  }
}

input,
div,
span,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#under-menu-container {
  position: absolute;
  top: 44px;
  backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  z-index: 10;
}

#under-menu-container #links-container {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  max-width: 990px;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

#under-menu-container .left-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-weight: 500;
}

#under-menu-container .left-container .title {
  font-size: 21px;
  font-weight: 600;
  text-align: left;
}

#under-menu-container .right-container a {
  text-decoration: none;
  font-size: 12px;
  opacity: 0.8;
  transition: opacity 400ms;
  color: black;
  padding: 8px;
  margin: 0 3px;
  font-weight: 500;
}

#under-menu-container .right-container a.order-now {
  padding: 5px 8px;
  margin-left: 5px;
  background-color: #0071e3;
  color: white;
  opacity: 1;
  border-radius: 20px;
}

#under-menu-container .date {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
}

.fix-header {
  position: fixed !important;
  right: 0;
  left: 0;
  top: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.fix-links-container {
  border: none !important;
}

.content-container {
  padding-top: 59px;
}

html,
body {
  min-height: 100%;
}

/* animation container */

#animationWindow {
  width: 100%;
  height: 80vh;
  position: relative;
}

#animationWindow .links {
  position: absolute;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  gap: 40px;
  justify-content: center;
}

#animationWindow .title {
  padding-top: 50px;
}

#animationWindow .title h1 {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
}

#animationWindow .title h2 {
  font-size: 40px;
  text-align: center;
}

#animationWindow .links a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 500;
}

path {
  stroke: #fff;
}

/* video container */

#video-bg-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transform: scale(0);
  opacity: 0;
}

.should-not-be-fixed {
  position: relative !important;
  opacity: 1 !important;
  transform: scale(1) !important;
}

#video-bg-container video {
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
  max-width: unset;
}

#video-bg-container .text {
  position: absolute;
  color: white;
  font-size: 60px;
  font-weight: bold;
}

/* choose power */

#choose-power-container {
  min-height: 100vh;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

#choose-power-container h3 {
  font-size: 60px;
  font-weight: bold;
}

#choose-power-container .m1-container {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

#choose-power-container .m1-container div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#choose-power-container img {
  margin-top: 25px;
  width: 200px;
}

#choose-power-container p {
  margin-top: 25px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

/* Wheats up */

#wheatsup-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 680px;
  padding: 0 20px;
  margin: auto;
}

#wheatsup-container h3 {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 20px;
}

#wheatsup-container .text {
  font-size: 25px;
  text-align: left;
  font-weight: bold;
}

#wheatsup-container a {
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  padding: 5px 15px;
  border-radius: 20px;
  display: flex;
  font-weight: 500;
  align-items: center;
}

#wheatsup-container a div {
  border: 2px solid rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  font-size: 13px;
  padding-bottom: 2px;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

/* Healthy */

#healthy-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin: auto;
  background-color: #fafafa;
  padding: 40px 0;
}

#healthy-container img {
  margin: 10px 20px;
  max-width: 700px;
}

#healthy-container .image-container {
  padding: 20px;
  box-sizing: border-box;
}

#healthy-container .image-container img {
  max-width: 500px;
}

#healthy-container .right-container {
  margin: 20px;
  max-width: 400px;
}

#healthy-container .right-container h3 {
  font-weight: bold;
  font-size: 40px;
  margin-top: 0;
  padding-top: 0;
  text-align: left;
  line-height: 55px;
}

#healthy-container .text {
  text-align: left;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;
}

/* M1max container */

#m1max-container {
  margin: 50px 10% 0 10%;

  background-color: #fafafa;
  border-radius: 10px 10px 0 0;
}

#m1max-container .content {
  max-width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 50px;
}

#m1max-container .content h1 {
  font-size: 60px;
  font-weight: bold;

  background: #c97be5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #c97be5,
    #491a96
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #c97be5,
    #491a96
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

#m1max-container .content .text {
  font-weight: bold;
  line-height: 28px;
  font-size: 20px;
}

#m1max-container .content .speed-container {
  display: flex;
  margin-top: 40px;
  gap: 40px;
}

#m1max-container .content .speed-container h5 {
  color: #8b8b91;
  font-size: 15px;
  font-weight: 500;
}

#m1max-container .content .speed-container h3 {
  font-weight: 500;
  font-size: 50px;
  line-height: 50px;
}

#m1max-container .content .speed-container h4 {
  color: #8b8b91;
  font-size: 15px;
  font-weight: bold;
}

/* M1ultra container */

#m1ultra-container {
  background-color: #111111;
  padding: 40px 20px;
}

#m1ultra-container .content {
  max-width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 50px;
}

#m1ultra-container .content h1 {
  font-size: 60px;
  font-weight: bold;

  background: #c97be5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #c97be5,
    #491a96
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #c97be5,
    #491a96
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

#m1ultra-container .content .text {
  font-weight: bold;
  line-height: 28px;
  font-size: 20px;
  color: #86868b;
}

#m1ultra-container .content .speed-container {
  display: flex;
  margin-top: 40px;
  gap: 40px;
}

#m1ultra-container .content .speed-container h5 {
  color: #8b8b91;
  font-size: 15px;
  font-weight: 400;
}

#m1ultra-container .content .speed-container h3 {
  font-weight: 400;
  color: white;
  font-size: 50px;
  line-height: 50px;
}

#m1ultra-container .content .speed-container h4 {
  color: #8b8b91;
  font-size: 15px;
  font-weight: bold;
}

/* weknow */

#weknow-container {
  padding: 100px 20px 0 20px;
  max-width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-size: 55px;
  font-weight: bold;
  line-height: 65px;
}

#weknow-container .note {
  font-size: 20px;
}

/* footer */

footer a {
  text-decoration: none;
  color: #0070c9;
  font-weight: 500;
}

footer .sds-watermark {
  position: absolute;
  right: -100px;
  top: 15px;
  opacity: 0.2;
  height: 250px;
  cursor: pointer;
  z-index: 2;
}

footer a:hover {
  text-decoration: underline;
}

footer {
  overflow: hidden;
  position: relative;
  background-color: #f2f2f2;
  padding: 0 22px;
  padding-bottom: 21px;
  overflow: hidden;
  font-weight: 500;
}

.footer-content {
  max-width: 980px;
  margin: 0 auto;
}

.footer-menu {
  padding-top: 21px;
  overflow: hidden;
}

.rotating {
  -webkit-animation: rotating 60s linear infinite;
  animation: rotating 60s linear infinite;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.footer-col {
  width: 20%;
  float: left;
}

footer input[type="checkbox"] {
  display: none;
}

footer h3 {
  color: #333;
  font-size: 14px;
  position: relative;
  z-index: 1;
  background: #f2f2f2;
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 0;
}

.product-list {
  padding: 0;
  list-style: none;
  margin: 0;
}

.product-list li {
  margin-bottom: 5px;
  line-height: 1;
}

.product-list a {
  color: #555;
  display: inline-block;
  font-size: 12px;
}

.product-list a:hover {
  color: #333;
}

footer input[type="checkbox"]:checked + .col-section .product-list {
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  visibility: visible;
  position: static;
  transform: none;
}

footer input[type="checkbox"]:checked + .col-section h3::after {
  transform: rotate(45deg) scale(1.08);
}

.footer-base {
  padding-top: 34px;
  padding-bottom: 21px;
  color: #888;
  font-size: 12px;
}

.footer-more-ways {
  margin-bottom: 7px;
  padding-bottom: 6px;
  border-bottom: 1px solid #d6d6d6;
}

.nowrap {
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  footer {
    padding: 0 16px;
    padding-bottom: 21px;
  }

  .footer-col {
    width: 100%;
  }

  footer .item {
    border-bottom: 1px solid #d6d6d6;
  }

  footer h3 {
    padding: 10px 0;
    margin: 0;
    cursor: pointer;
  }

  footer h3::after {
    content: "+";
    float: right;
    font-size: 14px;
    font-weight: bold;
    margin-top: -2px;
    margin-right: 8px;
    transition: transform 0.3s ease;
  }

  .product-list {
    visibility: hidden;
    position: absolute;
    transform: translateY(-100px);
    z-index: 1;
    padding: 5px 0 16px 0;
  }

  .product-list li {
    margin: 0;
  }

  .product-list a {
    display: block;
    padding: 6px 14px;
  }

  .footer-col {
    overflow: hidden;
  }
}

@media only screen and (min-width: 767px) {
  .item-padding-top {
    padding-top: 24px;
  }
}

.footer-legal-copyright {
  margin-right: 30px;
  float: left;
  margin-top: 3px;
}

.footer-legal-links {
  float: left;
}

.footer-legal-links a {
  border-right: 1px solid #d6d6d6;
  margin-right: 10px;
  padding-right: 12px;
  display: inline-block;
  margin-top: 3px;
  white-space: nowrap;
  color: #555;
}

.footer-legal-links a:last-child {
  border-right: none;
}

.footer-legal-links a:hover {
  color: #333;
}

@media screen and (max-width: 1000px) {
  #healthy-container img {
    max-width: 400px;
  }
}

@media screen and (max-width: 700px) {
  #healthy-container {
    max-width: 100%;
  }

  #healthy-container img {
    max-width: 300px !important;
  }

  #video-bg-container .text {
    font-size: 40px;
    text-align: center;
  }

  #wheatsup-container h3 {
    font-size: 40px;
  }

  #wheatsup-container .text {
    font-size: 20px;
  }

  #m1max-container {
    margin: 50px 0;
  }

  #m1max-container .content,
  #m1ultra-container .content {
    padding: 20px;
  }

  #m1max-container .content h3,
  #m1ultra-container .content h3 {
    font-size: 30px !important;
  }
}
